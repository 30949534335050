import { useAttendanceClosedContext } from ".";

export const Header = () => {
	const { event } = useAttendanceClosedContext();
	const clientLogo = event?.client?.clientContext?.showLogoInEmails ? event?.client?.logo : null;

	return (
		<header className="w-screen bg-white">
			<div className="flex items-center justify-between max-w-6xl px-4 py-2 mx-auto md:py-4">
				<div className="flex items-center w-full sm:w-auto">
					{clientLogo ? (
						<img src={clientLogo} alt="Client Logo" className="w-auto h-8 sm:h-10" />
					) : (
						<img src="/goodera-logo.png" alt="Goodera Logo" className="w-auto h-8 sm:h-10" />
					)}
				</div>
			</div>
		</header>
	);
};
