import { RouteObject } from "react-router-dom";
import { ReactNode, Suspense } from "react";
import { Spinner } from "./components/base/spinner";
import App from "./App";
import VolunteerForm from "./pages/submission/[id]";
import Ticket from "./pages/ticket";
import { VRP } from "./pages/vrp";
import { AttendanceClosed } from "./pages/attendance-closed/[id]";

function WithSuspense({ children }: { children: ReactNode }) {
	return <Suspense fallback={<Spinner />}>{children}</Suspense>;
}

const routes: RouteObject[] = [
	{
		path: "/",
		element: <App />,
		children: [
			{
				path: "/submission/:id",
				element: (
					<WithSuspense>
						<VolunteerForm />
					</WithSuspense>
				),
			},
			{
				path: "/ticket/:eventId/:customerId",
				element: (
					<WithSuspense>
						<Ticket />
					</WithSuspense>
				),
			},
			{
				path: "/vrp/:id",
				element: (
					<WithSuspense>
						<VRP />
					</WithSuspense>
				),
			},
			{
				path: "/attendance-closed/:id",
				element: (
					<WithSuspense>
						<AttendanceClosed />
					</WithSuspense>
				),
			},
		],
	},
];

export default routes;
