export const AttendanceClosedText = () => {
	return (
		<div className="w-screen bg-white">
			<div className="max-w-6xl px-4 mx-auto md:px-0">
				<div className="py-10 md:py-20">
					<div className="text-4xl font-bold md:text-5xl">Attendance Closed</div>
					<div className="text-base text-[#888888] mt-4 md:text-lg">
						The attendance window for this event has closed. Thank you for your interest.
					</div>
				</div>
			</div>
		</div>
	);
};
