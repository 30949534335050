import { createContext, useContext } from "react";

import { useParams } from "react-router-dom";
import { useEvent } from "../../../hooks/use-event";
import { EventWithState } from "../../../types/event";
import { Header } from "./header";
import { AttendanceClosedText } from "./attendance-closed-text";
import { EventDetails } from "./event-details";
export const AttendanceClosedContext = createContext<{ event: EventWithState } | null>(null);

export const AttendanceClosed = () => {
	const { id: slotID = "" } = useParams();
	const { data: eventDetails } = useEvent(slotID);

	if (!eventDetails) return null;
	return (
		<AttendanceClosedContext.Provider value={{ event: eventDetails }}>
			<div className="flex flex-col items-center h-screen bg-white w-screen text-[#444444] font-buenos px-6 sm:p-0">
				<Header />
				<EventDetails />
				<AttendanceClosedText />
			</div>
		</AttendanceClosedContext.Provider>
	);
};

export function useAttendanceClosedContext() {
	const context = useContext(AttendanceClosedContext);
	if (!context) {
		throw new Error("useVrpContext error");
	}
	return context;
}
